import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { style: {"font-size":"1.9em","font-weight":"bold","text-transform":"uppercase"} }
const _hoisted_2 = {
  key: 0,
  class: "mr-2",
  style: {"font-size":"1.9em","font-weight":"bold","text-transform":"uppercase"}
}
const _hoisted_3 = {
  key: 0,
  class: "ops d-flex flex-column",
  style: {"width":"100%","height":"8vh"}
}
const _hoisted_4 = {
  key: 1,
  class: "ops d-flex flex-column",
  style: {"width":"100%","height":"8vh"}
}

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_v_icon = _resolveComponent("v-icon")
  const _component_v_col = _resolveComponent("v-col")
  const _component_v_row = _resolveComponent("v-row")
  const _component_v_alert = _resolveComponent("v-alert")
  const _component_v_card_title = _resolveComponent("v-card-title")
  const _component_v_card = _resolveComponent("v-card")

  return (_openBlock(), _createBlock(_component_v_card, {
    class: "d-flex mt-2 ml-2 mr-2",
    color: $props.color
  }, {
    default: _withCtx(() => [
      _createVNode(_component_v_row, null, {
        default: _withCtx(() => [
          _createVNode(_component_v_col, {
            cols: "1",
            style: _normalizeStyle($options.colorStatusSensor),
            class: "d-flex justify-center align-center"
          }, {
            default: _withCtx(() => [
              ($props.state_sensor == 'OFFLINE')
                ? (_openBlock(), _createBlock(_component_v_icon, {
                    key: 0,
                    class: "ml-3",
                    icon: "mdi-lan-disconnect",
                    size: "31"
                  }))
                : (_openBlock(), _createBlock(_component_v_icon, {
                    key: 1,
                    class: "ml-3",
                    icon: "mdi-lan-connect",
                    size: "31"
                  }))
            ]),
            _: 1 /* STABLE */
          }, 8 /* PROPS */, ["style"]),
          _createVNode(_component_v_col, { cols: "11" }, {
            default: _withCtx(() => [
              _createVNode(_component_v_row, { class: "d-flex flex-column align-start mt-1" }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_col, {
                    cols: "12",
                    class: "d-flex justify-space-between"
                  }, {
                    default: _withCtx(() => [
                      _createElementVNode("small", _hoisted_1, _toDisplayString($props.name), 1 /* TEXT */),
                      ($props.status != 'offline')
                        ? (_openBlock(), _createElementBlock("small", _hoisted_2, _toDisplayString(_ctx.tempExecucao), 1 /* TEXT */))
                        : _createCommentVNode("v-if", true)
                    ]),
                    _: 1 /* STABLE */
                  })
                ]),
                _: 1 /* STABLE */
              }),
              ($props.status != 'offline')
                ? (_openBlock(), _createBlock(_component_v_row, {
                    key: 0,
                    class: "d-flex flex-column align-start",
                    xs: "12",
                    sm: "6",
                    md: "4"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_col, { cols: "12" }, {
                        default: _withCtx(() => [
                          _createVNode(_component_v_card_title, { class: "d-flex justify-start align-end white--text font-weight-bold text-uppercase" }, {
                            default: _withCtx(() => [
                              ($props.status == 'producao')
                                ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                                    ($props.op)
                                      ? (_openBlock(), _createBlock(_component_v_alert, {
                                          key: 0,
                                          style: {"padding":"0px 0px 0px 10px","width":"100%","font-size":"100px","font-weight":"bold"},
                                          color: "#388E3C",
                                          width: "100%",
                                          title: 'ORDEM DE PRODUÇÃO: ' + $props.op
                                        }, null, 8 /* PROPS */, ["title"]))
                                      : (_openBlock(), _createBlock(_component_v_alert, {
                                          key: 1,
                                          style: {"padding":"0px 0px 0px 10px","width":"100%","font-size":"100px","font-weight":"bold"},
                                          color: "#FFFB02",
                                          title: "O.P NÃO INFORMADA"
                                        }))
                                  ]))
                                : _createCommentVNode("v-if", true),
                              ($props.status == 'parada')
                                ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                                    ($props.op)
                                      ? (_openBlock(), _createBlock(_component_v_alert, {
                                          key: 0,
                                          style: {"padding":"0px 0px 0px 10px","width":"100%","font-size":"100px","font-weight":"bold"},
                                          color: "#388E3C",
                                          width: "100%",
                                          title: "ORDEM DE PRODUÇÃO: {{ op }}"
                                        }))
                                      : _createCommentVNode("v-if", true),
                                    ($props.motivo_de_parada)
                                      ? (_openBlock(), _createBlock(_component_v_alert, {
                                          key: 1,
                                          style: {"padding":"0px 0px 0px 10px","width":"100%","font-size":"100px","font-weight":"bold"},
                                          color: "#FB8C01",
                                          width: "100%",
                                          title: 'MOTIVO DE PARADA : ' + $props.motivo_de_parada
                                        }, null, 8 /* PROPS */, ["title"]))
                                      : (_openBlock(), _createBlock(_component_v_alert, {
                                          key: 2,
                                          style: {"padding":"0px 0px 0px 10px","width":"100%","font-size":"100px","font-weight":"bold"},
                                          color: "#FB8C01",
                                          width: "100%",
                                          title: "MOTIVO DE PARADA NÃO INFORMADO"
                                        }))
                                  ]))
                                : _createCommentVNode("v-if", true)
                            ]),
                            _: 1 /* STABLE */
                          })
                        ]),
                        _: 1 /* STABLE */
                      })
                    ]),
                    _: 1 /* STABLE */
                  }))
                : _createCommentVNode("v-if", true)
            ]),
            _: 1 /* STABLE */
          })
        ]),
        _: 1 /* STABLE */
      })
    ]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["color"]))
}