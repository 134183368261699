<template>
        <v-card height="100%">
            <toolbarCharts @toolbarFiltros="toolbarFiltros" />
            <div class="status_mchine">
                <v-card>
                    <v-row class="mt-2 mx-2">
                        <v-col cols="4" sm="4" md="4" class="px-0 pr-1">
                            <v-card color="#2E7D32" dark class="text-start d-flex rounded mb-2"  rounded>
                                <div class="content_Card">
                                    <span class="ml-1 truncate-text">TRABALHANDO</span>
                                    <span class="ml-auto second-span">{{ appFabricaAtual.resourceRecursos.producao.length }}</span>
                                </div>
                            </v-card>
                        </v-col>

                        <!-- Coluna Parado -->
                        <v-col cols="4" sm="4" md="4" class="px-1">
                            <v-card color="#D50000" class="text-start d-flex rounded mb-2"  rounded>
                                <div class="content_Card">
                                    <span class="ml-1">PARADO</span>
                                    <span class="mr-2">{{ appFabricaAtual.resourceRecursos.parada.length }}</span>
                                </div>
                            </v-card>
                        </v-col> 

                        <!-- Coluna Setup, Manutenção, Desligada -->
                        <v-col cols="4" class="d-flex my-n3 px-0 pl-1">
                            <v-col cols="4" class="pr-1 px-0">
                                <v-card color="#FFA500" class="text-start d-flex rounded mb-2" >
                                    <div class="content_Card d-flex justify-between w-100">
                                        <span class="ml-1 truncate-text">SETUP</span>
                                        <span class="ml-auto second-span">{{ appFabricaAtual.resourceRecursos.setup.length }}</span>
                                    </div>
                                </v-card>
                            </v-col>
                            <v-col cols="4" class="px-0">
                                <v-card color="#1976D2" class="text-start d-flex rounded mb-2" >
                                    <div class="content_Card d-flex justify-between w-100">
                                        <span class="ml-1 truncate-text">MANUTENÇÃO</span>
                                        <span class="ml-auto second-span">{{ appFabricaAtual.resourceRecursos.manutencao.length }}</span>
                                    </div>
                                </v-card>
                            </v-col>
                            <v-col cols="4" class="pl-1 pr-0">
                                <v-card color="#757575" class="text-start d-flex rounded mb-2" >
                                    <div class="content_Card d-flex justify-between w-100">
                                        <span class="ml-1 truncate-text">DESLIGADA</span>
                                        <span class="ml-auto second-span">{{ appFabricaAtual.resourceRecursos.offline.length }}</span>
                                    </div>
                                </v-card>
                            </v-col>
                        </v-col>
                    </v-row>
                </v-card>
            </div>
            <v-row class="mt-0">
                <v-col cols="4" md="4" sm="4">
                    <v-card class="ml-2" style="width: 100%; height: calc(100vh - 130px); padding: 8px 0px 0px 0px; background-color: #616161; overflow-y: auto;">
                        <v-row justify="start;">
                            <v-col cols="12" v-for="machine in appFabricaAtual.resourceRecursos.producao" :key="machine.name">
                                <card_recursos_status  :name="machine.nome" :motivo_de_parada="machine.desc_motivo_parada"
                                    :op="machine.ordem_de_producao" :nome_operador="machine.nome_operador" :state_sensor="machine.state_sensor"
                                    :dt_inicio="machine.dt_inicio" :color="'#2E7D32'" :status="'producao'" />
                            </v-col>
                        </v-row>
                    </v-card>
                </v-col>

                <v-col cols="4" md="4" sm="4">
                    <v-card style="width: 100%; height: calc(100vh - 130px); padding: 8px 0px 0px 0px; background-color: #616161; overflow-y: auto;">
                        <v-row justify="start;">
                            <v-col cols="12" v-for="machine in appFabricaAtual.resourceRecursos.parada" :key="machine.name">
                                <card_recursos_status  :name="machine.nome" :motivo_de_parada="machine.desc_motivo_parada"
                                    :op="machine.ordem_de_producao" :nome_operador="machine.nome_operador" :state_sensor="machine.state_sensor"
                                    :dt_inicio="machine.dt_inicio" :color="'#D50000'" :status="'parada'" />
                            </v-col>
                        </v-row>
                    </v-card>
                </v-col>

                <v-col cols="4" md="4" sm="4" class="pl-1">
                    <v-card class="mr-2" style="width: 100%;  height: calc(100vh - 130px); ;padding: 8px 0px 0px 0px; background-color: #616161; overflow-y: auto;">
                        <v-row justify="start">
                            <v-col cols="12" v-for="machine in appFabricaAtual.resourceRecursos.setup" :key="machine.name">
                                <card_recursos_status :name="machine.nome" :nome_operador="machine.nome_operador"
                                    :motivo_de_parada="machine.desc_motivo_parada" :op="machine.ordem_de_producao" :state_sensor="machine.state_sensor"
                                    :dt_inicio="machine.dt_inicio" :color="'#FFA500'" :status="'parada'" />
                            </v-col>
                            <v-col cols="12" v-for="machine in appFabricaAtual.resourceRecursos.manutencao" :key="machine.name">
                                <card_recursos_status  :name="machine.nome" :nome_operador="machine.nome_operador"
                                    :motivo_de_parada="machine.desc_motivo_parada" :op="machine.ordem_de_producao" :state_sensor="machine.state_sensor"
                                    :dt_inicio="machine.dt_inicio" :color="'#1976D2'" :status="'parada'" />
                            </v-col>
                            <v-col cols="12" v-for="machine in appFabricaAtual.resourceRecursos.offline" :key="machine.name">
                                <card_recursos_status  :name="machine.nome" :nome_operador="machine.nome_operador"
                                    :motivo_de_parada="machine.desc_motivo_parada" :op="machine.ordem_de_producao" :state_sensor="machine.state_sensor"
                                    :dt_inicio="machine.dt_inicio" :color="'#757575'" :status="'offline'" />
                            </v-col>
                        </v-row>
                    </v-card>
                </v-col>
            </v-row>
        </v-card>
</template>

<script>
import { useAppCharts } from '../../../stores/appCharts';
import { useAppMaquinas } from '../../../stores/appMaquinas';
import { useAppStore } from '../../../stores/app';
import { CalculaTempoMaquina } from '../../utils/funcoes';
import card_recursos_status from './cards/cards_recursos_status.vue';
import toolbarCharts from '../charts/toolbar/index.vue'
import { useAppFabricaAtual } from '../../../stores/appFabricaAtual';

export default {
    name: "charts_recursos_por_status",
    components: {
        card_recursos_status,
        toolbarCharts,
    },
    props: { apontamento: Object },
    data() {
        return {
            dialog: true,
            producao: [],
            parada: [],
            setup_manutencao_offline: [],
            timer: null,
            tempExecucao: '00:00:00',
            isMenuOpen: false,
            currentMachine: null,
        }

    },

    computed: {
        appFabricaAtual() {
            return useAppFabricaAtual();
        },
        appCharts() {
            return useAppCharts();
        },
        appStore() {
            return useAppStore().set_apontamento_realtime()
        },
        resourceMaquinas() {
            return useAppMaquinas().resourceMaquinas;
        },    
    },
    watch: {
     
    },
     created (){
        frappe.realtime.on('apontamento_app', (data) => {
            useAppFabricaAtual().listar_recursos_abertos().then(async ()=>{
                console.log('apontamento_app', data)
                const delay = (ms) => {
                    return new Promise(resolve => setTimeout(resolve, ms));
                }
            await delay(1000);
            useAppFabricaAtual().listar_recursos_abertos()
        })})

       frappe.realtime.on('recurso', (data) => {
        useAppFabricaAtual().listar_recursos_abertos().then(async ()=>{
            console.log('apontamento_app', data)
            const delay = (ms) => {
                    return new Promise(resolve => setTimeout(resolve, ms));
                }
            await delay(1000);
            useAppFabricaAtual().listar_recursos_abertos()
        })})
    },
    methods: {
       
    
        toolbarFiltros(pFiltro, pValue) {
            switch (pFiltro) {
                case 'machine':
                    console.log('maquinas: ', pValue)
                    break;
                case 'shift':
                    console.log('turno: ', pValue)
                    break;
                case 'group':
                    console.log('grupo: ', pValue)
                    break;
            };
        },
        tempoApontamento(dt_inicio) {
            this.tempExecucao = CalculaTempoMaquina(
                new Date(dt_inicio)
            ).tempo
        },
    }
};
</script>

<style scoped>
.pa-1 {
    padding: 3%;
}

.pa-2 {
    padding: 2%;
}

.pa-3 {
    padding: 16%;
}

.content_Card {
    display: flex;
    justify-content: space-between;
    align-items: center; 
    width: 100%
}

.content_Card span {
    font-size: 24px;
    font-weight: bold;
    margin: 0 5px;
}

.truncate-text {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 90%; /* Ajusta o tamanho máximo que o primeiro span pode ocupar */
}


@media (max-width: 1366px) {
    .content_Card span {
        font-size: 17px;
        font-weight: bold;
        margin: 0 2px;
    }
}

@media (max-width: 1000px) {
    .content_Card span {
        font-size: 12px;
        font-weight: bold;
        margin: 0 2px;
    }
}
</style>
