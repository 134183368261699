<template>
    <v-card class="d-flex mt-2 ml-2 mr-2"  :color="color">
        <v-row>
            <v-col cols="1" :style="colorStatusSensor" class="d-flex justify-center align-center">
                <v-icon v-if="state_sensor == 'OFFLINE'" class="ml-3" icon="mdi-lan-disconnect" size="31"></v-icon>
                <v-icon v-else class="ml-3" icon="mdi-lan-connect" size="31"></v-icon>
            </v-col>

            <v-col cols="11">
                <v-row class="d-flex flex-column align-start mt-1">
                    <v-col cols="12" class="d-flex justify-space-between">
                        <small style="font-size: 1.9em; font-weight: bold; text-transform: uppercase;">
                            {{ name }}
                        </small>

                        <small v-if="status != 'offline'" class="mr-2"
                            style="font-size: 1.9em; font-weight: bold; text-transform: uppercase;">
                            {{ tempExecucao }}
                        </small>
                    </v-col>
                </v-row>

                <v-row v-if="status != 'offline'" class="d-flex flex-column align-start" xs="12" sm="6" md="4">
                    <v-col cols="12">
                        <v-card-title class="d-flex justify-start align-end white--text font-weight-bold text-uppercase">
                            <div v-if="status == 'producao'" class="ops d-flex flex-column" style="width: 100%;height:8vh;">
                                <v-alert v-if="op" style="padding: 0px 0px 0px 10px; width: 100%;font-size: 100px;font-weight: bold;" color="#388E3C" width="100%"  :title="'ORDEM DE PRODUÇÃO: ' + op"></v-alert>
                                <v-alert v-else style="padding: 0px 0px 0px 10px; width: 100%;font-size: 100px;font-weight: bold;" color="#FFFB02"    title="O.P NÃO INFORMADA"></v-alert>
                            </div>

                            <div v-if="status == 'parada'" class="ops d-flex flex-column" style="width: 100%;height:8vh;">      
                                <v-alert v-if="op" style="padding: 0px 0px 0px 10px; width: 100%;font-size: 100px;font-weight: bold;" color="#388E3C"   width="100%"    title="ORDEM DE PRODUÇÃO: {{ op }}"></v-alert>
                                
                                <v-alert v-if="motivo_de_parada" style="padding: 0px 0px 0px 10px; width: 100%;font-size: 100px;font-weight: bold;" color="#FB8C01"  width="100%"    :title="'MOTIVO DE PARADA : ' + motivo_de_parada" ></v-alert>
                                <v-alert v-else style="padding: 0px 0px 0px 10px; width: 100%;font-size: 100px;font-weight: bold;" color="#FB8C01"  width="100%"    title="MOTIVO DE PARADA NÃO INFORMADO"></v-alert>
                            </div>
                        </v-card-title>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
    </v-card>
</template>

<script>

import { CalculaTempoMaquina } from '../../../utils/funcoes';

export default {
    name: "card_recursos_status",
    data: () => {
        return {
            count: 0,
            time: 0,
            tempExecucao: 0
        }
    },
    props: {
        name: String,
        op: String,
        status: String,
        dt_inicio: String,
        color: String,
        motivo_de_parada: String,
        nome_operador: String,
        state_sensor : String
    },
    computed: {
        colorStatusSensor() {

            let backgroundColor;

            switch (this.state_sensor) {
                case 'OFFLINE':
                    backgroundColor = '#37474F';
                    break;
                case 'ACTIVE':
                    backgroundColor = '#43A047';
                    break;
                case 'IDLE':
                    backgroundColor = '#D50000';
                    break;
                default:
                    backgroundColor = 'grey';
            }

            return { backgroundColor: backgroundColor };
        }
    },

    methods: {
        inicia_count_machine() {
            clearInterval(this.time);
            setInterval(() => {
                this.tempoApontamento()
                this.time++;
            }, 1000);
        },
        tempoApontamento() {
            this.tempExecucao = CalculaTempoMaquina(
                new Date(this.dt_inicio)
            ).tempo
        },
    },
    mounted() {
        this.inicia_count_machine()
    }
}
</script>